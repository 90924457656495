import { OutboundLink } from "gatsby-plugin-google-analytics";
import _ from "lodash";
import React from "react";
import DateRange from "../components/DateRange";
import { Layout } from "../components/index";
import { getAccessLevel, getPages, Link, safePrefix, shortUrl } from "../utils";

export default class Cv extends React.Component {
  render() {
    let workplaces = _.orderBy(
      getPages(this.props.pageContext.pages, "/work"),
      [
        function (object) {
          return new Date(object.frontmatter.start);
        },
      ],
      ["desc"]
    );

    let courses = _.orderBy(
      getPages(this.props.pageContext.pages, "/education"),
      [
        function (object) {
          return new Date(object.frontmatter.start);
        },
      ],
      ["desc"]
    );

    // console.log(courses);
    courses = _.filter(courses, function (page) {
      return (
        !page.frontmatter.hasOwnProperty("education") ||
        !page.frontmatter.education
      );
    });

    let educations = _.orderBy(
      getPages(this.props.pageContext.pages, "/education"),
      [
        function (object) {
          return new Date(object.frontmatter.start);
        },
      ],
      ["desc"]
    );
    educations = _.filter(educations, function (page) {
      return (
        page.frontmatter.hasOwnProperty("education") &&
        page.frontmatter.education
      );
    });

    let level = getAccessLevel("level");
    if (level < 3) {
      // remove courses
      courses = [];
      // only show latest workplaces and educations
      educations = educations.slice(0, 2);
      workplaces = workplaces.slice(0, 2);
    }

    let last_daterange = "";
    let last_detail = "";

    return (
      <Layout {...this.props}>
        <table className="cv">
          <tbody>
            {/**************************************/}
            <tr>
              <th className="col-heading col-personal" colSpan="2">
                Persönliche Daten
                {level > 2 && (
                  <div className="cv-thumbnail">
                    <img
                      src={safePrefix("images/me.webp")}
                      alt="Mein Profilbild"
                    />
                  </div>
                )}
              </th>
            </tr>
            <tr>
              <td className="col-date">Name</td>
              <td className="col-detail">Andreas Pauschenwein</td>
            </tr>
            <tr>
              <td className="col-date">Titel</td>
              <td className="col-detail">Ing., BA</td>
            </tr>
            <tr>
              <td className="col-date">Wohnort</td>
              <td className="col-detail">Burgenland, Österreich</td>
            </tr>
            <tr>
              <td className="col-date">Kontakt</td>
              <td className="col-detail contacts">
                {_.map(
                  _.get(this.props, "pageContext.site.data.social.links"),
                  (link, link_idx) =>
                    link && (
                      <div key={"link_" + link_idx}>
                        <span
                          className={"fab " + _.get(link, "icon")}
                          aria-hidden="true"
                        />
                        <span className="screen-reader-text">
                          {_.get(link, "title")}
                        </span>{" "}
                        <OutboundLink
                          href={safePrefix(_.get(link, "url"))}
                          target="_blank"
                          rel="noopener"
                        >
                          {shortUrl(_.get(link, "url"))}
                        </OutboundLink>
                      </div>
                    )
                )}
              </td>
            </tr>
            {/**************************************/}
            {workplaces.length > 0 && (
              <tr>
                <th className="col-heading col-workplaces" colSpan="2">
                  Berufserfahrung
                </th>
              </tr>
            )}
            {_.map(workplaces, (project, project_idx) => (
              <tr
                key={"workplace_" + project_idx}
                className={
                  "project-inside" +
                  (_.get(project, "frontmatter.highlight")
                    ? " row-highlight"
                    : "")
                }
              >
                <td className="col-date">
                  <DateRange {...project}></DateRange>
                </td>
                <td className="col-detail">
                  <Link
                    to={safePrefix(_.get(project, "url"))}
                    rel="bookmark"
                    className="col-detail-name"
                  >
                    {_.get(project, "frontmatter.company")}
                  </Link>
                  <p className="">
                    {_.get(project, "frontmatter.jobtitle")},{" "}
                    {_.get(project, "frontmatter.location")}
                  </p>
                  <cite className="citeblock">
                    {_.get(project, "frontmatter.excerpt")}
                    <br />
                    {_.get(project, "frontmatter.tools") &&
                      "Tools and Methoden: " +
                        _.get(project, "frontmatter.tools")}
                  </cite>
                </td>
              </tr>
            ))}
            {/**************************************/}
            {courses.length > 0 && (
              <tr>
                <th className="col-heading col-courses" colSpan="2">
                  Fortbildung
                </th>
              </tr>
            )}

            {_.map(courses, (project, project_idx) => {
              let daterange = "";
              let detail = "";
              let detailClassName = "";
              if (_.get(project, "frontmatter.institution") !== last_detail) {
                detail = _.get(project, "frontmatter.institution");
                last_detail = detail;
              } else {
                detail = "";
              }
              // if (<DateRange {...project}></DateRange> !== last_daterange) {
              //   daterange = <DateRange {...project}></DateRange>;
              //   last_daterange = daterange;
              // } else {
              //   daterange = "";
              // }
              if (_.get(project, "frontmatter.start") !== last_daterange) {
                daterange = <DateRange {...project}></DateRange>;
                last_daterange = _.get(project, "frontmatter.start");
              } else {
                daterange = "";
              }

              return (
                <tr
                  key={"course_" + project_idx}
                  className={
                    "project-inside" +
                    (_.get(project, "frontmatter.highlight")
                      ? " row-highlight"
                      : "")
                  }
                >
                  <td className="col-date">{daterange}</td>
                  <td className="col-detail">
                    <Link
                      to={safePrefix(_.get(project, "url"))}
                      rel="bookmark"
                      className="col-detail-name"
                    >
                      {detail}
                    </Link>
                    <p className="col-detail-close">
                      {_.get(project, "frontmatter.degree")}
                    </p>
                  </td>
                </tr>
              );
            })}
            {/**************************************/}
            <tr>
              <th className="col-heading col-educations" colSpan="2">
                Ausbildung
              </th>
            </tr>
            {_.map(educations, (project, project_idx) => (
              <tr
                key={"education_" + project_idx}
                className={
                  "project-inside" +
                  (_.get(project, "frontmatter.highlight")
                    ? " row-highlight"
                    : "")
                }
              >
                <td className="col-date">
                  <DateRange {...project}></DateRange>
                </td>
                <td className="col-detail">
                  <Link
                    to={safePrefix(_.get(project, "url"))}
                    rel="bookmark"
                    className="col-detail-name"
                  >
                    {_.get(project, "frontmatter.institution")}
                  </Link>
                  <p className="">{_.get(project, "frontmatter.department")}</p>
                  <cite className="citeblock">
                    Abschluss {_.get(project, "frontmatter.degree")}
                  </cite>
                </td>
              </tr>
            ))}
            {/**************************************/}
            <tr>
              <th className="col-heading col-hobbies" colSpan="2">
                Kenntnisse und Interessen
              </th>
            </tr>
            <tr>
              <td className="col-date">Sprachen</td>
              <td className="col-detail">
                {_.map(
                  _.get(this.props, "pageContext.site.data.author.languages"),
                  (listitem, list_idx) =>
                    listitem && <div key={"list_" + list_idx}>{listitem}</div>
                )}
              </td>
            </tr>
            <tr>
              <td className="col-date">Hobbies</td>
              <td className="col-detail">
                {_.map(
                  _.get(this.props, "pageContext.site.data.author.hobbies"),
                  (listitem, list_idx) =>
                    listitem && <div key={"list_" + list_idx}>{listitem}</div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </Layout>
    );
  }
}
